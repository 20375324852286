import { Box, Button, TableCell, Typography } from "@mui/material";
import { BasicTable, UIButton } from "components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getDepartments, getEmployee, selectingEmployeeList } from "api";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ModalDepartment } from "./ModalDepartment";
import {
  ChevronRight as ChevronRightIcon,
  Edit as EditIcon,
} from "@mui/icons-material/";
import { PropTypes } from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEmployeeSelected } from "@redux/slices/profile";

function generateDeparmentTree(
  departments,
  employees,
  parent,
  setValues,
  setOpen
) {
  let manager = employees.find((m) => m._id === parent.manager);
  let departments_childs = departments.filter((d) => d.parent === parent._id);

  let node = {
    title: (
      <Button
        size={"small"}
        onClick={() => {
          const departmentSelected = departments.find(
            (obj) => obj._id === parent._id
          );
          delete departmentSelected.createdAt;
          delete departmentSelected.updatedAt;

          setValues(departmentSelected);
          setOpen(true);
        }}
        startIcon={<EditIcon />}
      >
        {parent.name}
      </Button>
    ),
    subheader: manager?.fullName,
    avatar: manager?.thumbnail,
    cardStyle: null,
  };

  if (departments_childs.length) {
    node.childs = departments_childs.map((d) =>
      generateDeparmentTree(departments, employees, d, setValues, setOpen)
    );
  }

  if (!parent.isInternal) {
    node.cardStyle = {
      border: "2px dashed #cacfd2",
    };
  }

  return node;
}

function ChartTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`chart-tabpanel-${index}`}
      aria-labelledby={`chart-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

ChartTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const EmployeeButton = ({ employee }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const actionEmployeeClick = async (employee) => {
    try {
      const { data } = await getEmployee("id", employee._id);
      dispatch(setEmployeeSelected(data));
      navigate(
        "/cereza-organization/employees/profile/collaborator/personal-information"
      );
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  };

  return (
    <Button
      size={"small"}
      onClick={() => {
        actionEmployeeClick(employee);
      }}
      startIcon={<EditIcon />}
    >
      {employee.fullName}
    </Button>
  );
};

export const Departments = ({ setUpdateWizard = null }) => {
  const { t } = useTranslation(["organization"]["general"]);

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [parents, setParents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [managers, setManagers] = useState([]);
  const [departmentsTree, setDepartmensTree] = useState(null);
  const [values, setValues] = useState();
  const [change, setChange] = useState(true);
  const [employeesTree, setEmployeesTree] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const { data } = await selectingEmployeeList();
        setEmployees(data);

        setManagers(
          data.map((e) => {
            return { value: e._id, label: e.fullName };
          })
        );
      } catch (err) {
        console.log("Error getting employees: ", err);
      }
    };
    fetchManagers();
  }, []);

  const generateEmployeesChart = (department, employees, departments) => {
    let manager = employees.find((m) => m._id === department.manager);
    let departments_childs = departments.filter(
      (d) => d.parent === department._id
    );
    let employees_department = employees.filter(
      (e) => e.department === department.name && e._id !== manager._id
    );

    let parent_id = department.parent;
    let bc = [];

    while (parent_id !== undefined) {
      let parent = { ...departments.find((d) => d._id === parent_id) };
      bc.unshift(
        <Link
          underline="hover"
          key={parent._id}
          color="inherit"
          onClick={() => generateEmployeesChart(parent, employees, departments)}
        >
          {parent.name}
        </Link>
      );
      parent_id = parent.parent;
    }

    bc.unshift(
      <Link
        underline="hover"
        key={"general"}
        color="inherit"
        onClick={() => {
          let p = departments.find((d) => d.parent === undefined);
          setEmployeesTree(generateEmployeeTree(employees, departments, p));
          setBreadcrumbs(null);
        }}
      >
        {t("organization:General")}
      </Link>
    );

    setBreadcrumbs(bc);

    let node = {
      title: manager ? <EmployeeButton employee={manager} /> : "",
      subheader: department.name,
      avatar: manager?.thumbnail,
      cardStyle: null,
    };

    if (!department.isInternal) {
      node.cardStyle = {
        border: "2px dashed #cacfd2",
      };
    }

    if (departments_childs.length) {
      node.childs = departments_childs.map((d) =>
        generateEmployeeTree(employees, departments, d, t)
      );
    }

    if (employees_department.length) {
      if (node.childs === undefined) {
        node.childs = [];
      }

      node.childs = node.childs.concat(
        employees_department.map((e) => {
          return {
            title: <EmployeeButton employee={e} />,
            avatar: e.thumbnail,
          };
        })
      );
    }

    setEmployeesTree(node);
  };

  const generateEmployeeTree = (employees, departments, parent) => {
    let manager = employees.find((m) => m._id === parent.manager);
    let departments_childs = departments.filter((d) => d.parent === parent._id);
    let total_employes = employees.filter(
      (e) => e.department === parent.name && e._id !== manager?._id
    ).length;
    let subheader = parent.name;

    if (total_employes) {
      subheader +=
        total_employes === 1
          ? ` ${total_employes} ${t("employee:Employee")}`
          : ` ${total_employes} ${t("employee:Employees")}`;
    }

    let node = {
      title: manager?.fullName,
      subheader: (
        <Button
          onClick={() => generateEmployeesChart(parent, employees, departments)}
          endIcon={<ChevronRightIcon />}
        >
          {subheader}
        </Button>
      ),
      avatar: manager?.thumbnail,
      cardStyle: null,
    };

    if (departments_childs.length) {
      node.childs = departments_childs.map((d) =>
        generateEmployeeTree(employees, departments, d, t)
      );
    }

    if (!parent.isInternal) {
      node.cardStyle = {
        border: "2px dashed #cacfd2",
      };
    }

    let employees_department = employees.filter(
      (e) => e.department === parent.name && e._id !== manager?._id
    );

    if (employees_department.length) {
      if (node.childs === undefined) {
        node.childs = [];
      }

      node.childs = node.childs.concat(
        employees_department.map((e) => {
          return {
            title: <EmployeeButton employee={e} />,
            avatar: e.thumbnail,
          };
        })
      );
    }

    return node;
  };

  useEffect(() => {
    if (employees.length) {
      const fetchDepartments = async () => {
        try {
          const { data } = await getDepartments(null);
          setDepartments(data);

          setParents(
            data.map((e) => {
              return { value: e._id, label: e.name };
            })
          );

          let parent = data.find((d) => d.parent === undefined);
          setDepartmensTree(
            generateDeparmentTree(data, employees, parent, setValues, setOpen)
          );
          setEmployeesTree(generateEmployeeTree(employees, data, parent));

          setRows(
            data.map((e) => {
              let parent = data.find((d) => d._id === e.parent);

              return {
                id: e._id,
                name: e.name,
                parent: parent?.name,
                isInternal:
                  e.isInternal === true ? t("general:Yes") : t("general:No"),
              };
            })
          );
        } catch (err) {
          console.log("Error getting departments: ", err);
        }
      };

      fetchDepartments();
    }
  }, [change, employees]);

  const handleClick = (row) => {
    const departmentSelected = departments.find((obj) => obj._id === row.id);
    delete departmentSelected.createdAt;
    delete departmentSelected.updatedAt;

    setValues(departmentSelected);
    setOpen(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box p={2}>
        <Grid container={true}>
          <Grid item={"true"} xs={12} md={9}>
            <Typography variant="h5" mb={2}>
              {t("organization:departments")}
            </Typography>
          </Grid>
          <Grid item={"true"} xs={12} md={3} textAlign={"right"}>
            <UIButton
              onClick={() => {
                setValues({
                  name: "",
                  parent: "",
                  description: "",
                  isInternal: true,
                });
                setOpen(true);
              }}
            >
              {t("organization:Adddepartment")}
            </UIButton>
          </Grid>
        </Grid>
        <div
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <div
            style={{
              width: `fit-content`,
            }}
          >
            <BasicTable rows={rows} handleClick={handleClick}>
              <TableCell>{t("organization:name")}</TableCell>
              <TableCell>{t("organization:parent")}</TableCell>
              <TableCell>{t("organization:isInternal")}</TableCell>
            </BasicTable>
          </div>
        </div>
      </Box>
      <ModalDepartment
        open={open}
        setOpen={setOpen}
        parents={parents}
        managers={managers}
        employees={employees}
        deparments={departments}
        change={change}
        setChange={setChange}
        values={values}
        setUpdateWizard={setUpdateWizard}
      />
    </Box>
  );
};
