import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Stack, TableCell } from "@mui/material";
import {
  BasicTable,
  FilePreview,
  UIButton,
  UIDatePicker,
  UISelect,
  UITextField,
} from "components";
import { profileSelect } from "@redux/slices/profile";
import { generalSelect, setCollages } from "@redux/slices/general";
import { getCollages, getReferences } from "api";
import { getLabel, STUDY_LEVELS } from "utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ModalAcademy } from "./ModalAcademy";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import { translateSelectors } from "helpers";

dayjs.extend(utc);

export const FormAcademicHistory = ({
  formik,
  newData,
  canAdd,
  canChange,
  canAddAcademy,
  user = null,
}) => {
  const { t } = useTranslation(["employee"]["options"]);

  const dispatcher = useDispatch();
  const { collages } = useSelector(generalSelect);
  const { employeeSelected } = user ? user : useSelector(profileSelect);

  const [rows, setRows] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [src, setSrc] = useState();
  const [srcPath, setSrcPath] = useState("");

  const [openModalAcademy, setOpenModalAcademy] = useState(false);

  const disabled = (!canAdd && formik.values?._id === undefined) ||
    (!canChange && formik.values?._id !== undefined) ||
    formik.isSubmitting;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getReferences(
          employeeSelected._id,
          "academicBG",
        );
        console.log(data);
        const cleanData = data.map((item) => ({
          id: item._id,
          level: getLabel(STUDY_LEVELS, item.level),
          fieldOfStudy: item.fieldOfStudy,
          endDate: dayjs(item.endDate).format("L"),
        }));
        setRows(cleanData);
        setResponseData(data);
      } catch (err) {
        console.error("Error getting academic history:", err);
      }
    };

    fetchData();
  }, [employeeSelected, newData]);

  useEffect(() => {
    const fetchData = async () => {
      if (collages?.length === 0 || !collages || collages === undefined) {
        try {
          const { data } = await getCollages("selectors");
          dispatcher(setCollages(data));
        } catch (err) {
          console.error("Error getting skills:", err);
        }
      }
    };
    fetchData();
  }, []);

  const handleClick = (row) => {
    const historySelected = {
      ...responseData.find((obj) => obj._id === row.id),
    };
    delete historySelected.createdAt;
    delete historySelected.updatedAt;
    setSrcPath(historySelected.filePath);
    delete historySelected.filePath;

    const updatedAcademicHistory = {
      ...historySelected,
      endDate: dayjs.utc(historySelected.endDate),
    };

    formik.setValues(updatedAcademicHistory);
  };
  return (
    <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
      <Grid item={true} xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item={true} xs={12}>
            <UISelect
              formik={formik}
              label={t("employee:Niveldeeducación")}
              name={"level"}
              options={translateSelectors(t, STUDY_LEVELS)}
              disabled={disabled}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <UITextField
              formik={formik}
              label={t("employee:Campodeestudio")}
              name={"fieldOfStudy"}
              disabled={disabled}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Grid container spacing={2}>
              <Grid item={true} xs>
                <UISelect
                  formik={formik}
                  label={t("employee:Institucióneducativa")}
                  name={"institution"}
                  options={collages}
                  disabled={disabled}
                />
              </Grid>
              {canAddAcademy && (
                <Grid item={true} xs={3}>
                  <Stack>
                    <UIButton onClick={() => setOpenModalAcademy(true)}>
                      <AddCircleOutlineIcon />
                    </UIButton>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <ModalAcademy
              open={openModalAcademy}
              setOpen={setOpenModalAcademy}
              collages={collages}
              setCollages={setCollages}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <UIDatePicker
              formik={formik}
              label={t("employee:Fechadefinalización")}
              name={"endDate"}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <FilePreview
          formik={formik}
          name={"file"}
          src={src}
          setSrc={setSrc}
          srcPath={srcPath}
          lblBtnUpdate={t("employee:UploadFile")}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={5}>
        <BasicTable rows={rows} handleClick={handleClick}>
          <TableCell>{t("employee:Niveleducativo")}</TableCell>
          <TableCell>{t("employee:Campodeestudio")}</TableCell>
          <TableCell>{t("employee:Fechadefinalización")}</TableCell>
        </BasicTable>
      </Grid>
    </Grid>
  );
};
