import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { renderTimeViewClock, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FastField as Field, FieldArray } from "formik";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Close as CloseIcon } from "@mui/icons-material";
dayjs.extend(customParseFormat);

const DAYSNEXTVALIDATION = {
  monday: ["MONDAY", "TUESDAY"],
  tuesday: ["TUESDAY", "WEDNESDAY"],
  wednesday: ["WEDNESDAY", "THURSDAY"],
  thursday: ["THURSDAY", "FRIDAY"],
  friday: ["FRIDAY", "SATURDAY"],
  saturday: ["SATURDAY", "SUNDAY"],
  sunday: ["SUNDAY", "MONDAY"],
};

const calculateHours = (dayStart, timeStart, dayEnd, timeEnd) => {
  const workStartArray = timeStart.split(":");
  const workEndArray = timeEnd.split(":");

  let start = dayjs().set("hour", workStartArray[0]).set(
    "minute",
    workStartArray[1],
  );

  let end = dayjs().set("hour", workEndArray[0]).set(
    "minute",
    workEndArray[1],
  );

  if (dayStart !== dayEnd) {
    end = end.add(1, "day");
  }

  return dayjs(end, "HH:mm").diff(dayjs(start, "HH:mm"), "h");
};

const CustomTextField = ({ field, form: { touched, errors }, ...props }) => (
  <>
    <TextField {...field} {...props} />
    {touched[field.name] &&
      errors[field.name] && <div>{errors[field.name]}</div>}
  </>
);

const CustomTimePicker = (
  { field, form: { touched, errors, setFieldValue }, ...props },
) => {
  const format = "HH:mm";

  return (
    <>
      <TimePicker
        {...field}
        {...props}
        value={dayjs.utc(field.value, format)}
        format={format}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        onChange={(value) => setFieldValue(field.name, value.format(format))}
        slotProps={{ textField: { fullWidth: true } }}
      />
      {touched[field.name] &&
        errors[field.name] && <div>{errors[field.name]}</div>}
    </>
  );
};

const CustomAutocomplete = (
  { field, form: { touched, errors, setFieldValue }, ...props },
) => {
  const options = [{ value: "", label: "Seleccionar" }, ...props.options];
  const optionSelected = options.find((o) => o.value === field.value);

  return (
    <>
      <Autocomplete
        {...field}
        {...props}
        value={optionSelected ? optionSelected : ""}
        renderInput={(params) => <TextField {...params} {...props} />}
        onChange={(e, newValue) => {
          setFieldValue(field.name, newValue.value);
        }}
        isOptionEqualToValue={(o, v) => (o.value === v.value)}
        getOptionLabel={(o) => o.label}
        disableClearable={true}
        options={options}
      />
      {touched[field.name] &&
        errors[field.name] && <div>{errors[field.name]}</div>}
    </>
  );
};

const CustomSwitch = (
  { field, form: { touched, errors, setFieldValue }, ...props },
) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              {...field}
              {...props}
              checked={field.value}
              onChange={(e) => setFieldValue(field.name, e.target.checked)}
            />
          }
          {...props}
        />
      </FormGroup>
      {touched[field.name] &&
        errors[field.name] && <div>{errors[field.name]}</div>}
    </>
  );
};

const RowDay = (
  { formik, i_week, week, day, DAYS_CHOICES },
) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const validDaysEndWordk = DAYS_CHOICES.filter((d) =>
    DAYSNEXTVALIDATION[day].includes(d.value)
  );
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!week[day].day || formik.values.lunch === "NOT_APPLY") {
      formik.setFieldValue(`weeks[${i_week}].${day}.hasLunch`, false);
    }

    if (!week[day].day) {
      formik.setFieldValue(`weeks[${i_week}].${day}.workStart`, "");
      formik.setFieldValue(`weeks[${i_week}].${day}.dayWorkEnd`, "");
      formik.setFieldValue(`weeks[${i_week}].${day}.workEnd`, "");
    } else {
      if (!week[day].workStart) {
        formik.setFieldValue(`weeks[${i_week}].${day}.workStart`, "00:00");
      }
      if (!week[day].workEnd) {
        formik.setFieldValue(`weeks[${i_week}].${day}.workEnd`, "00:00");
      }
    }
  }, [week[day].day, formik.values.lunch]);

  useEffect(() => {
    if (!week[day].hasLunch) {
      formik.setFieldValue(`weeks[${i_week}].${day}.dayLunchStart`, "");
      formik.setFieldValue(`weeks[${i_week}].${day}.lunchStart`, "");
      formik.setFieldValue(`weeks[${i_week}].${day}.dayLunchEnd`, "");
      formik.setFieldValue(`weeks[${i_week}].${day}.lunchEnd`, "");
    } else {
      if (!week[day].lunchStart) {
        formik.setFieldValue(`weeks[${i_week}].${day}.lunchStart`, "00:00");
      }
      if (!week[day].lunchEnd) {
        formik.setFieldValue(`weeks[${i_week}].${day}.lunchEnd`, "00:00");
      }
    }
  }, [week[day].hasLunch]);

  useEffect(() => {
    let { lunchStart, lunchEnd } = week[day];

    if (lunchStart && lunchEnd) {
      setDuration(
        dayjs(lunchEnd, "HH:mm").diff(dayjs(lunchStart, "HH:mm"), "h", true),
      );
    } else {
      setDuration(0);
    }
  }, [week[day].lunchStart, week[day].lunchEnd]);

  return (
    <Box mb={2}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={2}>
          <Field
            name={`weeks[${i_week}].${day}.day`}
            label={t(`shiftAttendance:${day}`)}
            component={CustomSwitch}
            disabled={formik.isSubmitting}
          />
        </Grid>
        {week[day].day
          ? (
            <Fragment>
              <Grid item={true} xs={12} md={2}>
                <Field
                  name={`weeks[${i_week}].${day}.workStart`}
                  label={t("shiftAttendance:hourStart")}
                  component={CustomTimePicker}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={2}>
                <Field
                  name={`weeks[${i_week}].${day}.dayWorkEnd`}
                  label={t("shiftAttendance:dayWorkEnd")}
                  component={CustomAutocomplete}
                  options={validDaysEndWordk}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={2}>
                <Field
                  name={`weeks[${i_week}].${day}.workEnd`}
                  label={t("shiftAttendance:hourEnd")}
                  component={CustomTimePicker}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              {week[day].day && formik.values.lunch !== "NOT_APPLY"
                ? (
                  <Grid item={true} xs={12} md={3}>
                    <Field
                      name={`weeks[${i_week}].${day}.hasLunch`}
                      label={t("shiftAttendance:hasLunch")}
                      component={CustomSwitch}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                )
                : ""}
            </Fragment>
          )
          : ""}
      </Grid>
      <Grid container={true} spacing={2} mt={1}>
        {week[day].hasLunch && formik.values.lunch !== "NOT_APPLY"
          ? (
            <Fragment>
              <Grid item={true} xs={12} md={2}>
              </Grid>
              <Grid item={true} xs={12} md={2}>
                <Field
                  name={`weeks[${i_week}].${day}.dayLunchStart`}
                  label={t("shiftAttendance:dayLunchStart")}
                  component={CustomAutocomplete}
                  options={validDaysEndWordk}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={2}>
                <Field
                  name={`weeks[${i_week}].${day}.lunchStart`}
                  label={t("shiftAttendance:hourLunchStart")}
                  component={CustomTimePicker}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={2}>
                <Field
                  name={`weeks[${i_week}].${day}.dayLunchEnd`}
                  label={t("shiftAttendance:dayLunchEnd")}
                  component={CustomAutocomplete}
                  options={validDaysEndWordk}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={2}>
                <Field
                  name={`weeks[${i_week}].${day}.lunchEnd`}
                  label={t("shiftAttendance:hourLunchEnd")}
                  component={CustomTimePicker}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={2}>
                <Typography variant="body2">
                  {t("shiftAttendance:Duration")}
                </Typography>
                <Typography>
                  {duration} {duration === 1
                    ? t("shiftAttendance:hour")
                    : t("shiftAttendance:hours")}
                </Typography>
              </Grid>
            </Fragment>
          )
          : ""}
      </Grid>
    </Box>
  );
};

export const WeekTotalHours = ({ week }) => {
  let totalHours = 0;
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  Object.keys(week).forEach((day) => {
    const {
      workStart,
      workEnd,
      dayWorkEnd,
      lunchStart,
      lunchEnd,
      dayLunchStart,
      dayLunchEnd,
    } = week[day];

    if (workStart && workEnd) {
      totalHours = totalHours +
        calculateHours(day.toUpperCase(), workStart, dayWorkEnd, workEnd);

      if (dayLunchStart && dayLunchEnd) {
        totalHours = totalHours -
          calculateHours(dayLunchStart, lunchStart, dayLunchEnd, lunchEnd);
      }
    }
  });

  return (
    <Stack
      mb={2}
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 1, sm: 1, md: 1 }}
      justifyContent="flex-end"
    >
      <Typography>
        {t("shiftAttendance:HoursForWeek")}
        {totalHours}
      </Typography>
    </Stack>
  );
};

export const FormWeeksSchedule = ({ formik, DAYS, DAYS_CHOICES }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const [tab, setTab] = useState("0");

  const tabChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabClose = useCallback((event, i_week) => {
    event.stopPropagation();

    let weeks = formik.values.weeks;
    let total = formik.values.weeks.length - 1;

    if (tab === i_week.toString() && total === i_week) {
      setTab((i_week - 1).toString());
    }

    weeks = weeks.filter((w, i) => i !== i_week);
    formik.setFieldValue("weeks", weeks);
  });

  return (
    <FieldArray
      name={"weeks"}
      render={(arrayHelpers) => (
        <Box>
          {formik.values.weeks && formik.values.weeks.length == 1
            ? formik.values.weeks.map((week, i_week) => (
              <Fragment key={i_week}>
                <WeekTotalHours week={week} />
                {DAYS.map((day) => (
                  <RowDay
                    key={day}
                    formik={formik}
                    i_week={i_week}
                    day={day}
                    week={week}
                    DAYS_CHOICES={DAYS_CHOICES}
                  />
                ))}
              </Fragment>
            ))
            : ""}
          {formik.values.weeks && formik.values.weeks.length > 1
            ? (
              <TabContext value={tab}>
                <div>
                  <TabList
                    onChange={tabChange}
                  >
                    {formik.values.weeks.map((week, i_week) => (
                      <Tab
                        value={i_week.toString()}
                        key={i_week}
                        label={
                          <span>
                            {`${t("shiftAttendance:Week")} ${i_week + 1}`}
                            <IconButton
                              size="small"
                              component="span"
                              sx={{ ml: 2 }}
                              onClick={(event) => tabClose(event, i_week)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </span>
                        }
                      />
                    ))}
                  </TabList>
                </div>
                {formik.values.weeks.map((week, i_week) => (
                  <TabPanel value={i_week.toString()} key={i_week}>
                    <WeekTotalHours week={week} />
                    {tab === i_week.toString()
                      ? DAYS.map((day) => (
                        <RowDay
                          key={day}
                          formik={formik}
                          i_week={i_week}
                          day={day}
                          week={week}
                          DAYS_CHOICES={DAYS_CHOICES}
                        />
                      ))
                      : ""}
                  </TabPanel>
                ))}
              </TabContext>
            )
            : ""}
        </Box>
      )}
    />
  );
};
