import { useTranslation } from "react-i18next";
import { ProfileTabs } from "components/ProfileTabs";
import {
  Box,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { ModalAsset } from "./ModalAsset";
import { TitleBox, UIButton, BasicTable } from "components";
import { profileSelect } from "@redux/slices/profile";
import { useSelector } from "react-redux";

export const Assets = () => {
  const { t } = useTranslation(["employee"]["general"]);

  const { employeeSelected } = useSelector(profileSelect);

  const [rows, setRows] = useState([
    {
      name: "Iphone",
      usageType: "continuo",
      brand: "Apple",
      impact: "low",
    },
  ]);
  const [open, setOpen] = useState(false);

  const handleClick = (row) => {
    console.log("row: ", row);
    setOpen(true);
  };

  return (
    <>
      <ProfileTabs tab_initial="assets" />
      <br />
      <Paper>
        <Box p={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item={true} xs={12} md={9}>
              <Typography variant="h5" mb={2}>
                {t("employee:Activosasignados")}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <UIButton sx={{ marginRight: "8px", width: "100%" }}>
                {t("employee:Asignaractivo")}
              </UIButton>
            </Grid>
          </Grid>
          <br />
          <BasicTable rows={rows} handleClick={handleClick}>
            <TableHead>
              <TableRow>
                <TableCell>{t("employee:Nombredelactivo")}</TableCell>
                <TableCell>{t("employee:Usodelactivo")}</TableCell>
                <TableCell>{t("employee:Marca")}</TableCell>
                <TableCell>{t("employee:Impactodelactivo")}</TableCell>
              </TableRow>
            </TableHead>
          </BasicTable>
        </Box>
      </Paper>
      <ModalAsset
        open={open}
        setOpen={setOpen}
        title={t("employee:Detalledelactivo")}
      />
    </>
  );
};
