import React, { useCallback, useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import {
  Avatar,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from "@mui/material";
import {
  CameraAlt as CameraAltIcon,
  CloudDownload as CloudDownloadIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from "@mui/icons-material";
import { AvatarCropper } from "../AvatarCropper";
import RocketWhite from "assets/illustrations/rocket-white.png";
import Webcam from "react-webcam";
import { useSelector } from "react-redux";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { getFiles } from "api/integrations";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { UIModal } from "..";

const videoConstraints = {
  width: 500,
  height: 500,
  facingMode: "user",
};

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

const FileView = ({ src }) => {
  var data_array = src.split(";");
  var data_type = data_array[0];

  switch (data_type) {
    case "data:application/pdf":
      const binStr = atob(data_array[1].replace("base64,", ""));
      const len = binStr.length;
      const arr = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      const blob = new Blob([arr], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      return (
        <embed
          src={url}
          style={{
            width: "100vw",
            height: "100vh",
          }}
        />
      );
    default:
      return (
        <img
          src={src}
          style={{
            width: "95%",
          }}
        />
      );
  }
};

const FileThumbnail = ({ src }) => {
  var data_type = src.split(";")[0];

  switch (data_type) {
    case "data:application/pdf":
      src = RocketWhite;
    default:
      src = src;
  }

  return <img src={src} alt="preview" width="150" height="150" />;
};

const ImageCrop = ({ srcRef, setSrc, open, setOpen, formik, name }) => {
  const handleCloseImageCrop = () => {
    setCrop(null);
    setOpen(false);
  };

  const [crop, setCrop] = useState(null);

  const onCropChange = (c) => {
    setCrop(c);
  };

  const onCropComplete = (c) => {
    setCrop(c);
    makeClientCropt();
  };

  const makeClientCropt = () => {
    let image = new Image();
    image.src = srcRef;
    if (image && crop?.width && crop?.height) {
      const croppedImageUrl = getCroppedImg(image, crop);

      setSrc(croppedImageUrl);
      formik.setFieldValue(name, croppedImageUrl);
      handleCloseImageCrop();
    } else {
      setSrc(srcRef);
      formik.setFieldValue(name, srcRef);
      handleCloseImageCrop();
    }
  };

  const getCroppedImg = (image, crop) => {
    const image_area_crop = document.getElementById("image-area-crop");
    const canvas = document.createElement("canvas");
    const scaleX = image_area_crop.naturalWidth / image_area_crop.width;
    const scaleY = image_area_crop.naturalHeight / image_area_crop.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return canvas.toDataURL();
  };

  return (
    <Dialog open={open} onClose={handleCloseImageCrop} maxWidth="xl">
      <DialogContent>
        <ReactCrop
          src={srcRef}
          crop={crop}
          ruleOfThirds
          onChange={onCropChange}
          // onComplete={onCropComplete}
        >
          <img
            id="image-area-crop"
            src={srcRef}
            style={{
              maxWidth: "100%",
              maxHeight: "500px",
              objectFit: "contain",
            }}
          />
        </ReactCrop>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseImageCrop}>Salir</Button>
        <Button onClick={makeClientCropt}>Aceptar</Button>
      </DialogActions>
    </Dialog>
  );
};

export const FilePreview = ({
  src,
  setSrc,
  lblBtnUpdate,
  srcPath = null,
  formik = null,
  name = null,
  isAvatar = false,
  BtnOpenAlt = null,
  disabled = false,
  acceptFile = null,
}) => {
  const { tenant } = useSelector(tenantSettingsSelect);
  src = src ? src : RocketWhite;

  useEffect(() => {
    const fetchSource = async () => {
      let source = srcPath ? srcPath : formik.values[name];

      if (source && !/data:/.test(source)) {
        try {
          const { data } = await getFiles(tenant._id, source);
          setSrc(data.content);
        } catch (err) {
          console.error("Error getting photo: ", err);
        }
      } else {
        setSrc(source);
      }
    };

    fetchSource();
  }, null);

  const [openZoom, setOpenZoom] = useState(false);
  const inputFile = useRef(null);

  const handleOpen = () => {
    setOpenZoom(true);
  };

  const handleClose = () => {
    setOpenZoom(false);
  };

  const [openUpload, setOpenUpload] = useState(false);

  const handleOpenUpload = () => {
    if (isAvatar) {
      setOpenUpload(true);
    } else {
      inputFile.current.click();
    }
  };

  const handleFileInputChange = (e) => {
    getBase64(e.target.files[0])
      .then((result) => {
        let file = result;
        if (/data:image/.test(result)) {
          setSrcRef(file);
          handleOpenImageCrop();
        } else {
          setSrc(file);
          formik.setFieldValue(name, file);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const webcamref = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamref.current.getScreenshot();
    setSrc(imageSrc);
    formik.setFieldValue(name, imageSrc);
    handleCloseCamera();

    if (isAvatar) {
      handleOpenUpload();
    }
  }, [webcamref]);
  const [openCamera, setOpenCamera] = useState(false);
  const handleOpenCamera = () => {
    setOpenCamera(true);
  };
  const handleCloseCamera = () => {
    setOpenCamera(false);
  };

  const [srcRef, setSrcRef] = useState(null);
  const [openImageCrop, setOpenImageCrop] = useState(false);
  const handleOpenImageCrop = () => {
    setOpenImageCrop(true);
  };

  const StackFilePreview = () => {
    return (
      <>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton size="large" disabled={disabled}>
            <CloudDownloadIcon />
          </IconButton>
          {isAvatar
            ? (
              <Avatar
                alt="preview"
                src={src}
                sx={{
                  width: 150,
                  height: 150,
                }}
              />
            )
            : (
              <>
                <FileThumbnail src={src} />
                {acceptFile
                  ? (
                    <input
                      type="file"
                      ref={inputFile}
                      onChange={handleFileInputChange}
                      accept={acceptFile}
                      style={{
                        display: "none",
                        overflow: "hidden",
                        position: "absolute",
                      }}
                    />
                  )
                  : (
                    <input
                      type="file"
                      ref={inputFile}
                      onChange={handleFileInputChange}
                      style={{
                        display: "none",
                        overflow: "hidden",
                        position: "absolute",
                      }}
                    />
                  )}
              </>
            )}
          <IconButton size="large" onClick={handleOpen} disabled={disabled}>
            <ZoomOutMapIcon />
          </IconButton>
        </Stack>

        <UIModal open={openZoom} setOpen={setOpenZoom}>
          <DialogContent>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <FileView src={src} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
          </DialogActions>
        </UIModal>
      </>
    );
  };

  return (
    <>
      <Stack direction="column" spacing={1}>
        {src && <StackFilePreview />}
      </Stack>
      {BtnOpenAlt ? BtnOpenAlt : (
        <ButtonGroup
          variant="text"
          size="large"
          style={{ width: "100%", margin: "0 auto", justifyContent: "center" }}
        >
          <Button
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={handleOpenUpload}
            disabled={disabled}
          >
            {lblBtnUpdate ? lblBtnUpdate : "Actualizar foto de perfil"}
          </Button>
          <Button
            onClick={handleOpenCamera}
            startIcon={<CameraAltIcon />}
            disabled={disabled}
          />
        </ButtonGroup>
      )}
      <AvatarCropper
        src={src}
        open={openUpload}
        setOpen={setOpenUpload}
        setSrcPhoto={setSrc}
        formik={formik}
        name={name}
      />
      <ImageCrop
        setSrc={setSrc}
        srcRef={srcRef}
        open={openImageCrop}
        setOpen={setOpenImageCrop}
        formik={formik}
        name={name}
      />
      <Dialog open={openCamera} onClose={handleCloseCamera}>
        <DialogContent>
          <Webcam
            audio={false}
            width={500}
            height={500}
            ref={webcamref}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCamera}>Salir</Button>
          <Button onClick={capture} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

FilePreview.propTypes = {
  lblBtnUpdate: PropTypes.any,
  src: PropTypes.any,
  setSrc: PropTypes.func.isRequired,
};
