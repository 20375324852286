import React, { useState } from "react";
import { PropTypes } from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Avatar from "react-avatar-edit";

export const AvatarCropper = ({ src, open, setOpen, setSrcPhoto, formik, name }) => {
  const [preview, setPreview] = useState(null);

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (view) => {
    setPreview(view);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    formik.setFieldValue(name, preview);
    setSrcPhoto(preview);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="avatar-cropper"
      aria-describedby="avatar-cropper"
    >
      <DialogTitle>
        Personaliza tu foto
      </DialogTitle>
      <DialogContent>
        <Avatar
          width={250}
          height={250}
          src={src}
          onCrop={onCrop}
          onClose={onClose}
          exportAsSquare={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Salir
        </Button>
        <Button onClick={handleSave} autoFocus disabled={!preview}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AvatarCropper.propTypes = {
  src: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setSrcPhoto: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.any.isRequired,
};
