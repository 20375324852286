import { Alert, Box } from "@mui/material";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { useSelector } from "react-redux";
import { parameterizationSelect } from "@redux/slices/parameterization";

export const TitleBox = ({ text = "" }) => {
  const { mode } = useSelector(parameterizationSelect);

  return (
    <>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          backgroundColor:
            mode === "light" ? "rgb(237, 247, 237)" : "rgb(12, 19, 13)",
        }}
      >
        <Alert icon={<PersonPinIcon fontSize="inherit" />} severity="success">
          {text}
        </Alert>
      </Box>
      <br />
    </>
  );
};
