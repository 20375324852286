import { Grid, Stack } from "@mui/material";
import {
  UIButton,
  UIDatePicker,
  UISelect,
  UISwitch,
  UITextField,
} from "components";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import dayjs from "dayjs";

const calculateHours = (dayStart, timeStart, dayEnd, timeEnd) => {
  const workStartArray = timeStart.split(":");
  const workEndArray = timeEnd.split(":");

  let start = dayjs().set("hour", workStartArray[0]).set(
    "minute",
    workStartArray[1],
  );

  let end = dayjs().set("hour", workEndArray[0]).set(
    "minute",
    workEndArray[1],
  );

  if (dayStart !== dayEnd) {
    end = end.add(1, "day");
  }

  return dayjs(end, "HH:mm").diff(dayjs(start, "HH:mm"), "h");
};

export const FormWorkSchedule = ({ formik, DAYS }) => {
  const minDate = formik.values.minDate
    ? dayjs(formik.values.minDate).utc()
    : dayjs().utc();
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const workScheduleTypes = [
    { value: "REGULAR", label: t("shiftAttendance:Regular") },
    { value: "ROTATING", label: t("shiftAttendance:Rotating") },
    { value: "FLEXIBLE", label: t("shiftAttendance:Flexible") },
  ];
  const lunchTypes = [
    { value: "EXACT_TIME", label: t("shiftAttendance:ExactTime") },
    { value: "ANY_TIME", label: t("shiftAttendance:AnyTime") },
    { value: "NOT_APPLY", label: t("shiftAttendance:NotApply") },
  ];

  useEffect(() => {
    if (formik.values.type !== "ROTATING" && formik.values.weeks.length > 1) {
      formik.setFieldValue("weeks", [formik.values.weeks[0]]);
    }

    if (formik.values.type !== "ROTATING") {
      formik.setFieldValue("rotations", 0);
    }
  }, [formik.values.type]);

  let totalHoursWorkSchedule = 0;

  formik.values.weeks.forEach((week) => {
    Object.keys(week).forEach((day) => {
      const {
        workStart,
        workEnd,
        dayWorkEnd,
        lunchStart,
        lunchEnd,
        dayLunchStart,
        dayLunchEnd,
      } = week[day];

      if (workStart && workEnd) {
        totalHoursWorkSchedule = totalHoursWorkSchedule +
          calculateHours(day.toUpperCase(), workStart, dayWorkEnd, workEnd);

        if (dayLunchStart && dayLunchEnd) {
          totalHoursWorkSchedule = totalHoursWorkSchedule -
            calculateHours(dayLunchStart, lunchStart, dayLunchEnd, lunchEnd);
        }
      }
    });
  });

  return (
    <Grid container={true} spacing={2} mb={2}>
      <Grid item={true} xs={12} md={4}>
        <UITextField
          name={"name"}
          required={true}
          formik={formik}
          label={t("shiftAttendance:Name")}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <UITextField
          name={"description"}
          required={true}
          formik={formik}
          label={t("shiftAttendance:Description")}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item={true} xs={12} md={2}>
        <UISwitch
          formik={formik}
          required={true}
          label={t("shiftAttendance:Active")}
          name={"isActive"}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UISelect
          formik={formik}
          label={t("shiftAttendance:Type")}
          name={"type"}
          options={workScheduleTypes}
          isrequired={true}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UISelect
          formik={formik}
          label={t("shiftAttendance:Lunch")}
          name={"lunch"}
          options={lunchTypes}
          isrequired={true}
          disabled={formik.isSubmitting}
        />
      </Grid>
      {formik.values.type === "ROTATING"
        ? (
          <Fragment>
            <Grid item={true} xs={12} md={2}>
              <UITextField
                name={"rotations"}
                required={true}
                type="number"
                formik={formik}
                label={t("shiftAttendance:Rotations")}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <UIDatePicker
                formik={formik}
                label={t("shiftAttendance:startOfTheSchedule")}
                name={"startOfTheSchedule"}
                disabled={formik.isSubmitting}
                props={{ minDate }}
              />
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <UIButton
                label={t("shiftAttendance:addWeek")}
                onClick={() => {
                  let newWeek = {};

                  DAYS.forEach((day) => {
                    newWeek[day] = {
                      "workStart": "",
                      "workEnd": "",
                      "dayWorkEnd": "",
                      "hasLunch": false,
                      "lunchStart": "",
                      "dayLunchStart": "",
                      "lunchEnd": "",
                      "dayLunchEnd": "",
                      "day": false,
                    };
                  });

                  formik.setFieldValue("weeks", [
                    ...formik.values.weeks,
                    newWeek,
                  ]);
                }}
              />
            </Grid>
          </Fragment>
        )
        : ""}
      {formik.values.weeks.length > 1 && (
        <Grid item={true} xs={12} md={12}>
          <Stack
            component={Stack}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 1, md: 1 }}
            justifyContent="flex-end"
          >
            {t("shiftAttendance:HoursForWorkSchedule")}
            {totalHoursWorkSchedule}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};
