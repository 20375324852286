import * as React from "react";
import { useState, ReactNode } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import DevicesIcon from "@mui/icons-material/Devices";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AparmentIcon from "@mui/icons-material/Apartment";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ContactsIcon from "@mui/icons-material/Contacts";
import ConstructionIcon from "@mui/icons-material/Construction";
import SecurityIcon from "@mui/icons-material/Security";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import { useNavigate } from "react-router-dom";
import {
  ACCOUNT,
  ASSETS,
  EMPLOYEES,
  HOME,
  LERNING_CENTER,
  ORGANIZATION,
  PERFORMANCE_CENTER,
  RECRUITMENT,
  REQUESTS,
  RESOURCES,
  SETTINGS,
  SHIFT_ATTENDANCE,
  TOOLS,
} from "../routes";
import { useDispatch } from "react-redux";
import { setOpenDialogSearch } from "../redux/slices/general";
import { useTranslation } from "react-i18next";
import { authUserSelect } from "../redux/slices/authUser";
import { useSelector } from "react-redux";
import { unrestrictedRoutes } from "../App";

export const SideNav = () => {
  const { t } = useTranslation(["sidenav"]);
  const navigate = useNavigate();
  const dispacht = useDispatch();
  const [open, setOpen] = useState("");

  const { keycloakProfile, permissions } = useSelector(authUserSelect);

  const handleClick = (mainName) => {
    setOpen(mainName === open ? "" : mainName);
  };

  const navigateGenerator = (module, mainName, icon, mainRout) => {
    if (
      keycloakProfile?.is_superuser ||
      permissions?.paths?.includes(mainRout) ||
      unrestrictedRoutes.includes(mainRout)
    ) {
      if (module.length > 1) {
        return (
          <>
            <ListItemButton onClick={() => handleClick(mainName)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={mainName} />
              {open === mainName ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={open === mainName} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {module.map((route, index) => {
                  if (
                    keycloakProfile?.is_superuser ||
                    permissions?.paths?.includes(route?.route)
                  ) {
                    return (
                      <ListItemButton
                        key={index}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          navigate(route?.route);
                        }}
                      >
                        <ListItemIcon>
                          <ArrowRightRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t(`sidenav:${route?.name}`)} />
                      </ListItemButton>
                    );
                  }
                })}
              </List>
            </Collapse>
          </>
        );
      } else if ((module.length = 1)) {
        return (
          <>
            {module.map((route, index) => (
              <ListItemButton
                key={index}
                onClick={() => {
                  navigate(route.route);
                }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={t(`sidenav:${route.name}`)} />
              </ListItemButton>
            ))}
          </>
        );
      }
    }
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 390, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          color="primary"
          component="div"
          id="nested-list-subheader"
        >
          Cereza Organization
        </ListSubheader>
      }
    >
      <ListItemButton
        sx={{
          display: {
            md: "none",
            xs: "flex",
          },
        }}
      >
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText
          primary="Search"
          onClick={() => {
            dispacht(setOpenDialogSearch(true));
          }}
        />
      </ListItemButton>
      <ListItemButton
        sx={{
          display: {
            md: "none",
            xs: "flex",
          },
        }}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Add" />
      </ListItemButton>
      <ListItemButton
        sx={{
          display: {
            md: "none",
            xs: "flex",
          },
        }}
      >
        <ListItemIcon>
          <NotificationsNoneIcon />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </ListItemButton>
      {navigateGenerator(
        HOME,
        t("sidenav:Home"),
        <DashboardIcon />,
        "/cereza-organization/home/indicators",
      )}
      {navigateGenerator(
        REQUESTS,
        t("sidenav:Requests"),
        <ReduceCapacityIcon />,
        "/cereza-organization/requests",
      )}
      {/* {navigateGenerator(
        ACCOUNT,
        t("sidenav:Cuenta"),
        <ManageAccountsIcon />,
        "/cereza-organization/Cuenta"
      )} */}
      {navigateGenerator(
        ORGANIZATION,
        t("sidenav:Organización"),
        <AparmentIcon />,
        "/cereza-organization/Organización",
      )}
      {navigateGenerator(
        RECRUITMENT,
        t("sidenav:Reclutamiento"),
        <AssignmentIndIcon />,
        "/cereza-organization/Reclutamiento",
      )}
      {navigateGenerator(
        EMPLOYEES,
        t("sidenav:Empleados"),
        <ContactsIcon />,
        "/cereza-organization/Empleados",
      )}
      {navigateGenerator(
        SHIFT_ATTENDANCE,
        t("sidenav:TurnoDeAsistencia"),
        <PunchClockIcon />,
        "/cereza-organization/TurnoDeAsistencia",
      )}
      {/* {navigateGenerator(
        LERNING_CENTER,
        t("sidenav:CentroDeAprendizaje"),
        <CastForEducationIcon />,
        "/cereza-organization/CentroDeAprendizaje"
      )} */}
      {/* {navigateGenerator(
        PERFORMANCE_CENTER,
        t("sidenav:CentroDeRendimiento"),
        <CastForEducationIcon />,
        "/cereza-organization/CentroDeRendimiento"
      )} */}
      {/* {navigateGenerator(
        ASSETS,
        t("sidenav:Activos"),
        <DevicesIcon />,
        "/cereza-organization/Activos"
      )} */}
      {/* {navigateGenerator(
        RESOURCES,
        t("sidenav:Recursos"),
        <ViewModuleIcon />,
        "/cereza-organization/Recursos"
      )} */}
      {/* {navigateGenerator(
        TOOLS,
        t("sidenav:Herramientas"),
        <ConstructionIcon />,
        "/cereza-organization/Herramientas"
      )} */}
      {navigateGenerator(
        SETTINGS,
        t("sidenav:Configuraciones"),
        <SecurityIcon />,
        "/cereza-organization/Configuraciones",
      )}
    </List>
  );
};
