import { Autocomplete, TextField } from "@mui/material";
import { Field, useField } from "formik";

export const UISelectMultipleFast = (props) => {
  const { name, options, ...rest } = props;
  const [field] = useField(name);

  let option_selected = field.value
    ? field.value.map((v) => props.options.find((o) => o.value === v))
    : [];

  return (
    <Field {...field} {...rest}>
      {({ field, form: { touched, errors, setFieldValue } }) => (
        <>
          <Autocomplete
            fullWidth
            multiple
            value={option_selected}
            getOptionLabel={(o) => o.label}
            isOptionEqualToValue={(o, v) => o.value === v.value}
            onChange={(event, newValue) => {
              return setFieldValue(
                name,
                newValue.map((o) => o.value),
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                required={rest.required ? !field.value.length : false}
              />
            )}
            {...rest}
            options={options}
          />
          {touched[field.name] && errors[field.name] && (
            <div>{errors[field.name]}</div>
          )}
        </>
      )}
    </Field>
  );
};
