import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Filter, ProfileTabs, TitleBox, UIPaper } from "components";
import { getEmployeeRecordHistory } from "api";
import { profileSelect } from "@redux/slices/profile";
import { uiSelect } from "@redux/slices/ui";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  GpsFixed as GpsFixedIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const icons = {
  person_add: <PersonAddIcon />,
  defult: <GpsFixedIcon />,
};

export const EventDot = ({ event }) => {
  const getIcon = (icon) => {
    if (icons.hasOwnProperty(icon)) {
      return icons[icon];
    } else {
      return icons["defult"];
    }
  };

  return <TimelineDot color={event.color}>{getIcon(event.icon)}</TimelineDot>;
};

export const RecordHistory = (
  { application = "organization", user = null },
) => {
  const { preferences } = useSelector(uiSelect);

  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [history, setHistory] = useState([]);
  const [renderData, setRenderData] = useState([]);

  const { t } = useTranslation(["employee"]);

  const [filters, setFilters] = useState({
    hasFilter: false,
    groupsConfiguration: {},
    groups: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getEmployeeRecordHistory(employeeSelected._id);
        setHistory(data.recordHistory);
        setFilters({
          hasFilter: false,
          groupsConfiguration: {
            year: {
              title: t("employee:Years"),
              options: data.filterOptions.years,
            },
            eventType: {
              title: t("employee:Recordtypes"),
              options: data.filterOptions.eventTypes,
            },
          },
          groups: {
            year: new Set(),
            eventType: new Set(),
          },
        });
      } catch (err) {
        console.error("Error getting record history:", err);
      }
    };

    fetchData();
  }, [employeeSelected]);

  return (
    <>
      <ProfileTabs tab_initial="record history" application={application} />
      <Grid
        container
        spacing={4}
        flexDirection={{
          xs: "column",
          md: "row",
        }}
      >
        <Grid item="true" md={3} xs={12}>
          <UIPaper>
            <Filter
              title={t("employee:Loghistoryfilter")}
              filters={filters}
              setFilters={setFilters}
              renderData={history}
              setRenderData={setRenderData}
            />
          </UIPaper>
        </Grid>
        <Grid item="true" md={9} xs={12}>
          <UIPaper>
            <Box p={2} m={2}>
              <TitleBox
                text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
              />
              <Timeline position="alternate">
                {renderData.map((event, index) => {
                  return (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <EventDot event={event} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography variant="h5" component="span">
                          {event.eventType}
                        </Typography>
                        <Typography variant="subtitle2">
                          {event.day}/{event.month}/{event.year}
                        </Typography>
                        <Typography>
                          {event.info[preferences.language.abb]}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </Box>
          </UIPaper>
        </Grid>
      </Grid>
    </>
  );
};
