import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  Paper,
} from "@mui/material";
import { ProfileTabs, TitleBox, UIButton, UIModal } from "components";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Add as AddIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import { ModalDocument } from "./ModalDocument";
import { getDocuments, getFiles } from "api";
import { useSelector } from "react-redux";
import { profileSelect } from "@redux/slices/profile";
import NoImage from "assets/illustrations/no-image.png";
import {
  CloudDownload as CloudDownloadIcon,
  Edit as EditIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from "@mui/icons-material";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { useNavigate } from "react-router-dom";
import { useAccess } from "hooks";
import MimePdfIcon from "assets/illustrations/application-pdf.svg";
import { pathEmployee } from "helpers";

export const DocumentMediaPreview = ({
  documentSrc,
  setDocumentSelected,
  setOpen,
  setOpenZoom,
  setSrcZoom,
  canChange,
}) => {
  const { tenant } = useSelector(tenantSettingsSelect);
  const [preview, setPreview] = useState(NoImage);
  const [viewPreview, setViewPreview] = useState(false);
  const [previewZoom, setPreviewZoom] = useState(NoImage);
  const [cardMediaSx, setCardMediaSx] = useState({
    padding: "1em 1em 0 1em",
    objectFit: "contain",
  });

  useEffect(() => {
    const fetchSource = async () => {
      var documentSrc_type = documentSrc.filePath.split(".")[1];

      switch (documentSrc_type) {
        case "pdf":
          setPreview(MimePdfIcon);
          setViewPreview(true);
          break;

        case "jpg":
        case "jpeg":
        case "png":
          if (documentSrc.thumbnail) {
            setPreview(documentSrc.thumbnail);
            setViewPreview(true);
          } else {
            const { data } = await getFiles(tenant._id, documentSrc.filePath);
            setPreview(data.content);
            setViewPreview(true);
          }
          setCardMediaSx({});
          break;
      }

      setPreviewZoom(documentSrc);
    };

    fetchSource();
  }, []);

  const download = async (file) => {
    console.log(file);
    const { data } = await getFiles(tenant._id, file.filePath);
    const data_array = data.content.split(";");
    const data_type = data_array[0];
    console.log(data);
    console.log("data_type => ", data_type);
    const binStr = atob(data_array[1].replace("base64,", ""));
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: data_type.replace("data:", "") });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name || "downloaded-file";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <CardMedia
        component="img"
        image={preview}
        height={150}
        sx={cardMediaSx}
      />
      <CardContent>
        <Typography variant="subtitle2" component="div" noWrap={true}>
          {documentSrc.name}
        </Typography>
        <Typography variant="caption" component="div" color="text.secondary">
          {documentSrc.description}
        </Typography>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {documentSrc.labels.map((label, index) => {
            return <Chip key={index} label={label} />;
          })}
        </Stack>
      </CardContent>
      <CardActions>
        <IconButton
          onClick={() => {
            download(previewZoom);
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
        {viewPreview ? (
          <IconButton
            onClick={() => {
              setSrcZoom(previewZoom);
              setOpenZoom(true);
            }}
          >
            <ZoomOutMapIcon />
          </IconButton>
        ) : (
          ""
        )}
        {canChange && documentSrc.filesIsOwner ? (
          <IconButton
            onClick={() => {
              setDocumentSelected(documentSrc);
              setOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          ""
        )}
      </CardActions>
    </>
  );
};

export const FileView = ({ documentSrc }) => {
  const { tenant } = useSelector(tenantSettingsSelect);
  const [file, setFile] = useState(<CircularProgress />);

  useEffect(() => {
    const fetchSource = async () => {
      try {
        const { data } = await getFiles(tenant._id, documentSrc.filePath);
        const src = data.content;
        const data_array = src.split(";");
        const data_type = data_array[0];

        if (/data:application\/pdf/.test(data_type)) {
          const binStr = atob(data_array[1].replace("base64,", ""));
          const len = binStr.length;
          const arr = new Uint8Array(len);

          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }

          const blob = new Blob([arr], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);

          setFile(
            <embed
              src={url}
              style={{
                width: "100vw",
                height: "100vh",
              }}
            />
          );
        } else if (/data:image/.test(data.content)) {
          setFile(
            <img
              src={src}
              style={{
                width: "95%",
              }}
            />
          );
        } else {
          setFile(
            <img
              src={NoImage}
              style={{
                width: "95%",
              }}
            />
          );
        }
      } catch (err) {
        console.error("Error getting file: ", err);
      }
    };

    fetchSource();
  }, []);

  return file;
};

export const Documents = ({ application = "organization", user = null }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);

  const { employeeSelected } = user ? user : useSelector(profileSelect);

  const [open, setOpen] = useState(false);
  const [documentSrcSelected, setDocumentSelected] = useState(false);
  const [newData, setNewData] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [openZoom, setOpenZoom] = useState(false);
  const [srcZoom, setSrcZoom] = useState("");

  const { HasPermissions } = useAccess();
  const { canAdd, canChange } = HasPermissions(
    pathEmployee(application, "/profile/documents"),
    application
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getDocuments(employeeSelected._id);
        setResponseData(data);
      } catch (err) {
        console.error("Error getting countries:", err);
      }
    };

    fetchData();
  }, [newData]);

  return (
    <>
      <ProfileTabs tab_initial="documents" application={application} />
      <Paper>
        <Box pt={2} pb={2} m={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
          />
          <Typography variant="h5" mb={2}>
            {t("employee:Documents")}
          </Typography>
          <Grid container gap={1}>
            {canAdd && (
              <Grid
                item="true"
                md={2}
                xs={12}
                component={Button}
                variant="outlined"
                fullWidth={true}
                onClick={() => {
                  setDocumentSelected(null);
                  setOpen(true);
                }}
              >
                <AddIcon
                  sx={{
                    fontSize: 70,
                  }}
                />
              </Grid>
            )}
            {responseData.map((documentSrc, index) => {
              return (
                <Grid
                  item="true"
                  md={2}
                  xs={12}
                  key={documentSrc._id}
                  component={Card}
                >
                  <DocumentMediaPreview
                    documentSrc={documentSrc}
                    setOpen={setOpen}
                    key={documentSrc._id}
                    setDocumentSelected={setDocumentSelected}
                    cardExtra={{
                      elevation: 1,
                    }}
                    setOpenZoom={setOpenZoom}
                    setSrcZoom={setSrcZoom}
                    canChange={canChange}
                  />
                </Grid>
              );
            })}
          </Grid>
          {application === "organization" && (
            <Stack
              mt={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <UIButton
                variant="contained"
                startIcon={<ChevronLeftIcon />}
                onClick={() =>
                  navigate("/cereza-organization/employees/profiles")
                }
                fullWidth={false}
              />
            </Stack>
          )}
        </Box>
      </Paper>
      <ModalDocument
        open={open}
        setOpen={setOpen}
        newData={newData}
        setNewData={setNewData}
        document={documentSrcSelected}
        canAdd={canAdd}
        canChange={canChange}
      />
      <UIModal open={openZoom} setOpen={setOpenZoom}>
        <DialogContent>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <FileView documentSrc={srcZoom} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenZoom(false)}>Cerrar</Button>
        </DialogActions>
      </UIModal>
    </>
  );
};
