import { useEffect, useRef, useState } from "react";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Toast } from "components";
import {
  getAuthorizationSettings,
  putAuthorizationSettings,
  selectingEmployeeList,
} from "api";
import { MenuNav } from "../MenuNav";
import { FormikFlows } from "./FormikFlows";
import Swal from "sweetalert2";
import { VacationsAutorizations } from "./VacationsAutorizations";
import { useAccess } from "hooks";

export const AuthorizationSettings = () => {
  const { t } = useTranslation(["organization", "general"]);
  const { HasPermissions } = useAccess();
  const { canView, canChange } = HasPermissions(
    "/cereza-organization/settings/authorization-flows",
  );
  const { canView: canViewVacation } = HasPermissions(
    "/cereza-organization/settings/vacations-flow",
  );

  const [ubication, setUbication] = useState(
    canView ? "jobPositions" : canViewVacation ? "vacations" : "",
  );
  const [auth, setAuth] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeesValues, setEmployeesValues] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [update, setUpdate] = useState(false);

  const submitButtonRef = useRef(null);

  const triggerSubmit = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  useEffect(() => {
    const getAuthorizations = async () => {
      try {
        const { data } = await getAuthorizationSettings();
        setAuth(data);
      } catch (error) {
        console.log("getAuthorizationSettings: ", error);
        Toast.fire({
          icon: "error",
          title: error?.response?.data?.message,
        });
      }
    };
    getAuthorizations();

    const empleados = async () => {
      try {
        const { data } = await selectingEmployeeList();
        const employeeSelectors = data.map((employee) => ({
          _id: employee?._id,
          label: employee?.fullName,
        }));
        setEmployees(employeeSelectors);
        setEmployeesValues(
          data.map(({ _id: value, fullName: label }) => ({ value, label })),
        );
      } catch (error) {
        console.log("selectingEmployeeList: ", error);
      }
    };
    empleados();
  }, [update]);

  const fetchData = async (payload, updatedAll = false) => {
    try {
      await putAuthorizationSettings(payload._id, payload, updatedAll);
      setIsDirty(false);
      handleUnload();
      Toast.fire({
        icon: "success",
        title: t("organization:FlujoActualizadoConExito"),
      });
      setUpdate(!update);
    } catch (error) {
      console.log("putAuthorizationSettings: ", error);
      Toast.fire({
        icon: "error",
        title: error?.response?.data?.message,
      });
    }
  };

  const onSubmit = async (values) => {
    let payload = { ...auth };
    payload[ubication] = values;

    Swal.fire({
      text: t("organization:Texto1"),
      icon: "warning",
      confirmButtonColor: "#3085d6",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: t("organization:Texto2"),
      denyButtonText: t("organization:Texto3"),
    }).then((result) => {
      if (result.isConfirmed) {
        fetchData(payload, true);
      } else if (result.isDenied) {
        fetchData(payload);
      }
    });
  };

  const handleUnload = (current) => {
    if (current) {
      Object.keys(sessionStorage).forEach((key) => {
        if (
          key.startsWith("authorizationForm-") &&
          key !== `authorizationForm-${current}`
        ) {
          sessionStorage.removeItem(key);
        }
      });
    } else {
      Object.keys(sessionStorage).forEach((key) => {
        if (key.startsWith("authorizationForm-")) {
          sessionStorage.removeItem(key);
        }
      });
    }
  };

  let tabs = [];

  if (canView) {
    tabs.push(
      <Tab
        key="jobPositions"
        value="jobPositions"
        label={t("organization:jobPositions")}
        onClick={() => {
          if (isDirty) {
            AlertaNotification("jobPositions");
          } else {
            setUbication("jobPositions");
          }
        }}
      />,
    );

    tabs.push(
      <Tab
        key="manageJobPositions"
        value="manageJobPositions"
        label={t("organization:manageJobPositions")}
        onClick={() => {
          if (isDirty) {
            AlertaNotification("manageJobPositions");
          } else {
            setUbication("manageJobPositions");
          }
        }}
      />,
    );

    tabs.push(
      <Tab
        key="justifications"
        value="justifications"
        label={t("organization:justifications")}
        onClick={() => {
          if (isDirty) {
            AlertaNotification("justifications");
          } else {
            setUbication("justifications");
          }
        }}
      />,
    );

    tabs.push(
      <Tab
        key="attendancePermits"
        value="attendancePermits"
        label={t("organization:attendancePermits")}
        onClick={() => {
          if (isDirty) {
            AlertaNotification("attendancePermits");
          } else {
            setUbication("attendancePermits");
          }
        }}
      />,
    );
  }

  if (canViewVacation) {
    tabs.push(
      <Tab
        key="vacations"
        value="vacations"
        label={t("organization:Vacation")}
        onClick={() => {
          if (isDirty) {
            AlertaNotification("attendancePermits");
          } else {
            setUbication("vacations");
          }
        }}
      />,
    );
  }

  const AlertaNotification = (flow) => {
    return Swal.fire({
      title: t("organization:WarningText"),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: t("general:Guardar"),
      denyButtonText: t("general:NoGuardar"),
      cancelButtonText: t("general:Atras"),
    }).then((result) => {
      if (result.isConfirmed) {
        triggerSubmit();
      } else if (result.isDenied) {
        handleUnload();
        setIsDirty(false);
        Swal.fire(t("general:NoGuardado"), "", "info").then((result) => {
          setUbication(flow);
        });
      }
    });
  };

  return (
    <Paper>
      <MenuNav ubication={"Autorizaciones"} />
      <Box
        p={2}
        sx={{
          width: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        {(canView || canViewVacation) && (
          <Tabs
            value={ubication}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            style={{ maxWidth: "100%" }}
          >
            {tabs}
          </Tabs>
        )}
      </Box>
      {auth && (
        <>
          {ubication === "jobPositions" && (
            <FormikFlows
              auth={auth?.jobPositions}
              flow={"jobPositions"}
              onSubmit={onSubmit}
              employees={employees}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              submitButtonRef={submitButtonRef}
              canChange={canChange}
            />
          )}
          {ubication === "manageJobPositions" && (
            <FormikFlows
              auth={auth?.manageJobPositions}
              flow={"manageJobPositions"}
              onSubmit={onSubmit}
              employees={employees}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              submitButtonRef={submitButtonRef}
              canChange={canChange}
            />
          )}
          {ubication === "justifications" && (
            <FormikFlows
              auth={auth?.justifications}
              flow={"justifications"}
              onSubmit={onSubmit}
              employees={employees}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              submitButtonRef={submitButtonRef}
              canChange={canChange}
            />
          )}
          {ubication === "attendancePermits" && (
            <FormikFlows
              auth={auth?.attendancePermits}
              flow={"attendancePermits"}
              onSubmit={onSubmit}
              employees={employees}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              submitButtonRef={submitButtonRef}
              canChange={canChange}
            />
          )}
          {ubication === "vacations" && (
            <VacationsAutorizations employees={employeesValues} />
          )}
        </>
      )}
    </Paper>
  );
};
