import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";
import { Autocomplete } from "@mui/material";

export const TagInput = ({
  formik,
  label = "",
  name = "",
  readOnly = false,
  disabled = false,
  nameFileArray = "",
}) => {
  const [autoCompleteValue, setAutoCompleteValue] = useState(
    nameFileArray === ""
      ? formik.values[name]
      : formik.values[nameFileArray][index][name],
  );
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      name={name}
      clearIcon={false}
      freeSolo
      options={[]}
      multiple
      value={autoCompleteValue}
      inputValue={inputValue}
      disabled={disabled}
      renderTags={(value, props) =>
        value.map((option, index) => (
          <Chip label={option} {...props({ index })} />
        ))}
      onChange={(event, newValue, reason) => {
        setAutoCompleteValue(newValue);
        formik.setFieldValue(name, newValue);
      }}
      onBlur={(event) => {
        const options = event.target.value.split(" ");

        if (event.target.value) {
          const newOptions = autoCompleteValue
            .concat(options)
            .map((x) => x.trim())
            .filter((x) => x);
          setAutoCompleteValue(newOptions);
          formik.setFieldValue(name, newOptions);
          setInputValue("");
        }
      }}
      onInputChange={(event, newValue, reason) => {
        const options = newValue.split(" ");

        if (options.length > 1) {
          const newOptions = autoCompleteValue
            .concat(options)
            .map((x) => x.trim())
            .filter((x) => x);

          setAutoCompleteValue(newOptions);
          formik.setFieldValue(name, newOptions);
          setInputValue("");
        } else {
          setInputValue(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          readOnly={readOnly}
          disabled={disabled}
          label={label}
          {...params}
        />
      )}
    />
  );
};

TagInput.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
