import { useState } from "react";
import { ModalMap } from "components/Map/ModalMap";
import { UIPhoneNumber, UITextField } from "components";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Details = ({ formik, canAdd, canChange }) => {
  const { t } = useTranslation(["employee"]);

  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState(null);

  const handleClick = () => {
    formik.setFieldValue("address", address?.address);
    formik.setFieldValue("latitude", address?.lat);
    formik.setFieldValue("longitude", address?.lng);
  };
  const disabled = (!canAdd && !canChange) || formik.isSubmitting;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item={true} xs={12} md={3}>
          <Box
            onClick={() => {
              setOpen(true);
            }}
          >
            <UITextField
              formik={formik}
              label={t("employee:Dirección")}
              name={"address"}
              InputProps={{ readOnly: true }}
              disabled={disabled}
            />
          </Box>
          <UITextField
            formik={formik}
            label={t("employee:Latitud")}
            name={"latitude"}
            style={{ display: "none" }}
            disabled={disabled}
          />
          <UITextField
            formik={formik}
            label={t("employee:Longitud")}
            name={"longitude"}
            style={{ display: "none" }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextField
            formik={formik}
            label={t("employee:Descripción")}
            name={"description"}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextField
            formik={formik}
            label={t("employee:Correopersonal")}
            name={"personalEmail"}
            type={"email"}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UIPhoneNumber
            formik={formik}
            label={t("employee:Númerodecelular")}
            name={"cellphone"}
            isrequired={false}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UIPhoneNumber
            formik={formik}
            label={t("employee:Númerodeteléfono")}
            name={"phone"}
            isrequired={false}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <ModalMap
        open={open}
        setOpen={setOpen}
        setAddress={setAddress}
        address={address}
        handleClick={handleClick}
      />
    </>
  );
};
